export default {
  apiUrl: `${process.env.VUE_APP_API_URL}`,
  isApiProduction: true,
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_API_URL}/auth/login`,
  registerEndpoint: `${process.env.VUE_APP_API_URL}/auth/register`,
  refreshEndpoint: `${process.env.VUE_APP_API_URL}/auth/refresh-token`,
  logoutEndpoint: `${process.env.VUE_APP_API_URL}/profile/auth/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

}
